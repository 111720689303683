<template>
  <table>
    <thead id="tableHead">
    <tr>
      <th class="userCheckbox"><input type="checkbox" id="titleCheckbox" @change="checkAll()"></th>
      <th class="userId" @click="sortByField('id')">
        <div class="parent">
          <div class="left">
            {{ $t("userList.id") }}
          </div>
          <div class="right">
            <div class="top">
                <span class="fa fa-sort-up"
                      :class="equalsFields('id') ? (sortAndOrder() ? 'inactive' : 'active') : 'inactive'">
                </span>
            </div>
            <div class="bottom">
                <span class="fa fa-sort-down"
                      :class="equalsFields('id') ? (sortAndOrder() ? 'active' : 'inactive') : 'inactive'">
                </span>
            </div>
          </div>
        </div>
      </th>

      <th class="userRole" @click="sortByField('role', 'userRole')">
        <div class="parent">
          <div class="left">
            {{ $t("userList.role") }}
          </div>
          <div class="right">
            <div class="top">
                <span class="fa fa-sort-up"
                      :class="equalsFields('role') ? (sortAndOrder() ? 'inactive' : 'active') : 'inactive'">
                </span>
            </div>
            <div class="bottom">
                <span class="fa fa-sort-down"
                      :class="equalsFields('role') ? (sortAndOrder() ? 'active' : 'inactive') : 'inactive'">
                </span>
            </div>
          </div>
        </div>
      </th>

      <th class="name" @click="sortByField('username')">
        <div class="parent">
          <div class="left">
            {{ $t("userList.userName") }}
          </div>
          <div class="right">
            <div class="top">
                <span class="fa fa-sort-up"
                      :class="equalsFields('username') ? (sortAndOrder() ? 'inactive' : 'active') : 'inactive'">
                </span>
            </div>
            <div class="bottom">
                <span class="fa fa-sort-down"
                      :class="equalsFields('username') ? (sortAndOrder() ? 'active' : 'inactive') : 'inactive'">
                </span>
            </div>
          </div>
        </div>
      </th>

      <th class="userEmail" @click="sortByField('email')">
        <div class="parent">
          <div class="left">
            {{ $t("userList.email") }}
          </div>
          <div class="right">
            <div class="top">
                <span class="fa fa-sort-up"
                      :class="equalsFields('email') ? (sortAndOrder() ? 'inactive' : 'active') : 'inactive'">
                </span>
            </div>
            <div class="bottom">
                <span class="fa fa-sort-down"
                      :class="equalsFields('email') ? (sortAndOrder() ? 'active' : 'inactive') : 'inactive'">
                </span>
            </div>
          </div>
        </div>
      </th>

      <th class="userFirstName" @click="sortByField('firstName')">
        <div class="parent">
          <div class="left">
            {{ $t("userList.firstName") }}
          </div>
          <div class="right">
            <div class="top">
                <span class="fa fa-sort-up"
                      :class="equalsFields('firstName') ? (sortAndOrder() ? 'inactive' : 'active') : 'inactive'">
                </span>
            </div>
            <div class="bottom">
                <span class="fa fa-sort-down"
                      :class="equalsFields('firstName') ? (sortAndOrder() ? 'active' : 'inactive') : 'inactive'">
                </span>
            </div>
          </div>
        </div>
      </th>

      <th class="userLastName" @click="sortByField('lastName')">
        <div class="parent">
          <div class="left">
            {{ $t("userList.lastName") }}
          </div>
          <div class="right">
            <div class="top">
                <span class="fa fa-sort-up"
                      :class="equalsFields('lastName') ? (sortAndOrder() ? 'inactive' : 'active') : 'inactive'">
                </span>
            </div>
            <div class="bottom">
                <span class="fa fa-sort-down"
                      :class="equalsFields('lastName') ? (sortAndOrder() ? 'active' : 'inactive') : 'inactive'">
                </span>
            </div>
          </div>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <SoloUser ref="userList"
              @addRaw="changeDiv"
              v-for="user of users" :key="user.id"
              v-bind:user="user"
    />
    </tbody>
  </table>
</template>


<script>
import SoloUser from "./SoloUser";
import App from "../../App.vue"
import $ from 'jquery'
import {GlobalLocalizationEventEmitter} from "@/i18n";

export default {
  props: ['users'],
  components: {
    SoloUser: SoloUser
  },

  created() {
    GlobalLocalizationEventEmitter.$on("localizationChanged", () => {
      this.restoreOrders();
    })
  },

  beforeDestroy() {
    GlobalLocalizationEventEmitter.$off("localizationChanged", this);
  },

  watch: {
    users: function () {
      this.restoreOrders();
    }
  },

  data() {
    return {
      orderBy: {},
      field: '',
      clicked: false,
      checkedUsersList: [],
    }
  },

  methods: {
    restoreOrders() {
      const field = window.localStorage.getItem("users.field");
      const order = window.localStorage.getItem("users.orderBy") === 'true';
      const locPrefix = window.localStorage.getItem("users.locPrefix");
      if (field != null) {
        this.field = field
        this.orderBy = order;
        this.sortByField(null, locPrefix);
      }
    },

    sortByField(field, localizedPrefix) {
      if (field != null) {
        if (Object.is(this.field, field)) {
          this.orderBy = !this.orderBy;
        } else {
          this.field = field;
          this.orderBy = true;
        }

        window.localStorage.setItem("users.field", this.field);
        window.localStorage.setItem("users.orderBy", this.orderBy);
        if (localizedPrefix != null) {
          window.localStorage.setItem("users.locPrefix", localizedPrefix);
        } else {
          window.localStorage.removeItem("users.locPrefix");
        }
      }

      if (localizedPrefix != null) {
        this.users.sort(
            App.methods.comparator(a => this.$t(localizedPrefix + '.' + a[this.field]), this.orderBy)
        );
      } else {
        this.users.sort(
            App.methods.comparator(a => a[this.field], this.orderBy)
        );
      }
    },

    sortAndOrder() {
      return this.orderBy;
    },

    equalsFields(field) {
      return this.field === field;
    },

    changeDiv() {
      var userList = this.$refs.userList;
      this.checkedUsersList = [];
      userList.forEach(element => {
        if (element.$refs.checkbox.checked) {
          this.checkedUsersList.push(element.user.id);
        }
      });
      if (this.checkedUsersList.length > 0) {
        if (this.checkedUsersList.length === this.users.length) {
          $("#titleCheckbox").prop('checked', true);
        } else $("#titleCheckbox").prop('checked', false);
        this.$emit('setPanelVisible', this.checkedUsersList)
      } else {
        this.$emit('setHeaderVisible')
      }
    },

    checkAll() {
      var userList = this.$refs.userList;
      this.checkedUsersList = [];
      if ($("#titleCheckbox").prop('checked')) {
        userList.forEach(element => {
          element.$refs.checkbox.checked = true;
          this.checkedUsersList.push(element.user.id);
        });
        this.$emit('setPanelVisible', this.checkedUsersList)
      } else {
        userList.forEach(element => {
          element.$refs.checkbox.checked = false;
        });
        this.$emit('setHeaderVisible')
      }
    }
  },
}
</script>


<style scoped lang="scss">
@import "../../../public/styles/tables.scss";

th, td {
  border-bottom: 1px solid grey;
}

</style>