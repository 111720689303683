<template>
  <tr>
    <td class="userCheckboxTd">
      <input class="checkbox" type="checkbox" @change="countEvent()" ref="checkbox">
    </td>
    <td class="userIdTd" @click="doubleClick(user.id)">{{ user.id }}</td>
    <td :class="'userRoleTd ' + user.role" @click="doubleClick(user.id)">{{ $t("userRole." + user.role) }}</td>
    <td class="nameTd" @click="doubleClick(user.id)">{{ user.username }}</td>
    <td class="userEmailTd" @click="doubleClick(user.id)">{{ user.email }}</td>
    <td class="userFirstNameTd" @click="doubleClick(user.id)">{{ user.firstName }}</td>
    <td class="userLastNameTd" @click="doubleClick(user.id)">{{ user.lastName }}</td>
  </tr>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    },
  },
  methods: {
    doubleClick(id) {
      this.$router.push({name: 'user', params: {"id": id.toString()}});
    },
    countEvent() {
      this.$emit('addRaw')
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../../public/styles/tables.scss";

.GUEST {
  color: red;
}

.ADMIN {
  color: blue;
}

</style>