<template>
  <div id="TableContainer">
    <div>
      <Header table="users"
              ref="counter"
              @search="search"
              @deleteAll="deleteUsers"
              v-bind:hidePanel="hidePanel"/>
    </div>

    <vue-confirm-dialog></vue-confirm-dialog>

    <div id="table">
      <UserList
          v-bind:users="changedUsers"
          @setHeaderVisible="setHeaderVisible"
          @setPanelVisible="setPanelVisible"
      />
    </div>
  </div>
</template>


<script>
import axios from "axios";
import UserList from "./UserList";
import Header from "../Header";
import Link from "../utils/Link";
import VueConfirmDialog from "vue-confirm-dialog";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import ui from "../utils/ui";

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

let tableHeaderPosition = [0];

export default {
  components: {
    Header,
    UserList: UserList,
  },

  data() {
    return {
      users: [],
      hidePanel: true,
      array: [],
      sbActivity: true,
      lastQuery: (x) => x
    };
  },

  created: function () {
    window.addEventListener('scroll', this.scrolling);
    window.addEventListener('resize', this.windowResize);
  },

  destroyed() {
    window.removeEventListener('scroll', this.scrolling);
    window.addEventListener('resize', this.windowResize);
  },

  computed: {
    changedUsers: function () {
      return this.users.filter(x => this.lastQuery(x, this.isInclude));
    }
  },

  mounted() {
    this.getUsers().then(() =>{
      this.alignColumns();
      this.$emit("changeComponent", 'users');
    })
  },

  methods: {
    search(qsearch) {
      this.lastQuery = qsearch;
    },

    isInclude(user, query) {
      return this.fieldContain(query, user.id.toString())
          || this.fieldContain(query, this.$t("userRole." + user.role))
          || this.fieldContain(query, user.username)
          || this.fieldContain(query, user.email)
          || this.fieldContain(query, user.firstName)
          || this.fieldContain(query, user.lastName);
    },

    fieldContain(query, field) {
      if (field === null) {
        return false;
      }
      return field.toLowerCase().indexOf(query) !== -1;
    },

    setPanelVisible(array) {
      this.array = array;
      this.$refs.counter.changeLabel(array.length, "users");
      this.hidePanel = false;
    },

    setHeaderVisible() {
      this.hidePanel = true;
    },

    getUsers() {
      const headers = Link.methods.getHeaders();
      return axios.get(
        Link.methods.getAllUsersUrl(),
        {headers}
      ).then(res => {
        this.users = res.data;
      }).catch(() => {
        this.createToast(this.$t("mainUser.usersNotFound"), "error")
      });
    },

    deleteUsers() {
      this.$confirm(
        {
          message: this.$t("mainUser.confirmMessage"),
          button: {
            no: this.$t("mainUser.confirmNo"),
            yes: this.$t("mainUser.confirmYes")
          },
          callback: confirm => {
            if (confirm) {
              const headers = Link.methods.getHeaders();
              const promises = [];
              for (let i = 0; i < this.array.length; i++) {
                promises.push(axios.delete(Link.methods.getUserDeleteUrl(this.array[i]), {headers}));
              }
              Promise.all(promises).then(() => {
                this.array = [];
                this.getUsers();
                this.setHeaderVisible()
              }).catch(() => {
                this.createToast(this.$t("mainUser.usersDeleteErrorToast"), "error")
              });
            }
          }
        }
      )
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 3000
      });
    },

    windowResize() {
      this.alignColumns();
    },

    scrolling() {
      this.alignColumns();
      ui.methods.stickHeader(tableHeaderPosition);
    },

    alignColumns() {
      if (this.users.length > 0) {
        let rowWidth = 0;
        rowWidth += ui.methods.copyElementWidthIfDifferent('.userCheckboxTd',  'th.userCheckbox');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.userIdTd',        'th.userId');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.userEmailTd',     'th.userEmail');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.nameTd',          'th.name');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.userFirstNameTd', 'th.userFirstName');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.userLastNameTd',  'th.userLastName');
        rowWidth += ui.methods.copyElementWidthIfDifferent('.userRoleTd',      'th.userRole');
        ui.methods.copyElementWidthIfDifferent(rowWidth, '#tableHead');
      }
    }
  }
}
</script>


<style scoped lang="scss">
@import "../../../public/styles/tables.scss";
</style>